import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'

const SubmittedPage = () => (
	<Layout>
		<SEO title="Order submitted" />
		<div className="pricing-header px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center">
			<h1 className="display-4 mb-4">Order submitted</h1>
			<p className="lead mb-5">
				Thank you for filling the form. We will contact you to fullfil your
				order!
			</p>
		</div>

		<hr className="featurette-divider" />
	</Layout>
)

export default SubmittedPage
